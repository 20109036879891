<template>
  <v-container>
    <div class="text-center mt-10">
      <div style="margin: 0 auto;max-width:900px;position:relative;">
        <!-- <v-img :src="require('@/assets/images/home_cat.png')" style="max-width: 900px;" /> -->
        <!-- <img class="animate__animated animate__zoomIn animate__fadeInUpBig animate__slower" :src="require('@/assets/images/home_cat.png')" style="width: 100%;max-width: 900px;" /> -->

        <div v-if="showLoading" style="margin-top:100px">
          <div class="animate__animated animate__bounce animate__infinite" style="font-size: 64px; color:#a4885e">萌猫</div>
          <div class="animate__animated animate__flash animate__slower animate__infinite" style="font-size: 32px;padding-left:10px; color:#999">即将到来……</div> 
        </div>

        <CatPlaying ref="CatPlaying" />

        
<!-- 
        <a class="a-huangli" @click="btnHuangLi_OnClick()"></a>
        <a class="a-yuncheng" @click="btnYunCheng_OnClick()"></a> -->
      </div>
      <!-- <div class="animate__animated animate__fadeInUpBig animate__slower" id="example">Hello World</div>  -->
    </div>
  </v-container>
</template>

<style lang="less" scoped>
  .dancing_cat {
    margin: 0 auto;
    background-position: 0 0;
    width: 250px;
    height: 350px;
  }
  .a-huangli {
    position: absolute;
    left:0; 
    top:0;
    width: 50%;
    height: 100%;
    // background: rgba(0,0,0,0.5);
  }
  .a-yuncheng {
    position: absolute;
    left:50%; 
    top:0;
    width: 50%;
    height: 100%;
    // background: rgba(0,0,0,0.5);
  }
</style>

<script>
  import CatPlaying from '@/components/CatPlaying'
  export default {
    data: () => ({
      homeCatStyle: '',
      homeCatClass: '',
      background_src: '',
      dancing_num: 0,
      showLoading: true,
    }),
    components: {
      CatPlaying
    },
    mounted(){
      this.initHomePage()
    },
    methods: {
      initHomePage(){

        setTimeout(()=>{
          this.checkCatLoaded()
        },1500)
        

      },
      checkCatLoaded(){

        if (this.$refs.CatPlaying && this.$refs.CatPlaying.catLoaded){
          this.showLoading = false;
          this.$refs.CatPlaying.play()
        }else{
          setTimeout(()=>{
            this.checkCatLoaded()
          },500)
        }

      },
      btnHuangLi_OnClick(){
        this.$router.push('/huangli')
      },
      btnYunCheng_OnClick(){
        this.$router.push('/qian')
      }
    }
  }
</script>
