<template>
  <v-container class="pa-0">
    <div class="text-center mt-10">
      <div style="margin: 0 auto;max-width:100%;width: 100%;position:relative;"><!-- 
        <v-img :src="require('@/assets/images/photo_home/0001.jpg')" contain style="heightmax-width: 100%;width:100%" /> -->
        <div :style="`background-image:url(${require(`@/assets/images/photo_home/${home_cat_photo}`)});position:fixed;left:0;top:0;bottom:0;right:0;background-position:center center;background-size:cover`"></div>
        <div style="    position: fixed;
    inset: 0px;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 10%;">
          <div style="font-size:80px;color:#fff;margin-bottom: -20px;text-shadow: 0 0 15px rgba(0, 0, 0, .5),4px 4px 4px rgba(0, 0, 0, .5);">{{ current_time }}</div>
          <!-- <div style="font-size:32px;color:#fff;text-shadow: 0 0 10px rgba(0, 0, 0, .5),3px 3px 2px rgba(0, 0, 0, .5);">{{ current_date }} </div> -->


          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div 
                v-bind="attrs"
                v-on="on"
                style="font-size:24px;color:#fff;text-shadow: 0 0 10px rgba(0, 0, 0, .5),3px 3px 2px rgba(0, 0, 0, .5);">
                {{ current_say }}
              </div>
            </template>
            <v-list v-if="$store.getters.getMyName" dark>
              <!-- <v-list-item>
                <v-list-item-title @click="btnResetTodo_OnClick()">重设计划列表</v-list-item-title>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-title @click="btnResetName_OnClick()"><v-icon left>mdi-pencil</v-icon>修改名字</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>



        </div>
        <div style="position: fixed;top:10px;left:10px;">

          <v-btn
            @click="btnWelcome_OnClick()"
            text
            color="white"
          >
            <v-icon color="white" middle>mdi-home</v-icon>
            萌猫日历
          </v-btn>

        </div>
        <div style="position: fixed;top:10px;right:10px;">


          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="white"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white" middle>mdi-star-shooting</v-icon>
                萌猫陪你
              </v-btn>
            </template>
            <v-list dark dense>
              <v-list-item>
                <v-list-item-title @click="btnRandomHomeCat_OnClick()"><v-icon color="white" left>mdi-refresh-circle</v-icon>随机萌猫</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="btnChat_OnClick()"><v-icon color="white" left>mdi-chat-processing</v-icon>萌猫聊天</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title @click="btnResetName_OnClick()"><v-icon>mdi-pencil</v-icon>修改名字</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
          <v-btn
            @click="btnRiLi_OnClick()"
            text
            color="white"
          >
            <v-icon color="white" middle>mdi-calendar-range</v-icon>
          </v-btn>

        </div>
        <div style="position: fixed;bottom:10%;left: 10%;right: 10%;;text-align: center;">

          <div v-if="showInputCatChat">

            <div style="padding:10px;color:#fff;background:rgba(0,0,0,0.5);text-align: left;">{{ $store.getters.currentChatAnswer }}</div>

            <div style="padding:10px;color:#0ff;background:rgba(0,0,0,0.8);text-align: left;" v-if="$store.getters.currentChatLoading">我：{{ chat_talk_content }}</div>

            <div style="padding:10px;color:#ff0;background:rgba(0,0,0,0.8);text-align: left;" v-if="$store.getters.currentChatLoading">萌猫：正在思考中……</div>

            <div style="padding:10px;color:#fff;background:rgba(0,0,0,0.5);text-align: left;">
              
            <v-text-field
              v-if="!$store.getters.currentChatLoading"
              v-model="chat_talk_content"
              ref="textChatTalk"
              label="现在可以跟萌猫聊天啦！"
              dark
              @keydown.enter="btnCatTalk_OnClick()"
            ></v-text-field>

            </div>

            <div class="my-2"><v-btn :disabled="!chat_talk_content" @click="btnCatTalk_OnClick()" dark color="black">发消息给萌猫</v-btn></div>
            <div><v-btn @click="btnCancelCatTalk_OnClick()" dark color="grey darken-2">关闭聊天</v-btn></div>
          </div>

          <div v-if="showInputMyName">
            <v-text-field
              v-model="my_name"
              label="萌猫问：怎样称呼你？"
              dark
            ></v-text-field>
            <v-btn  :disabled="!my_name" @click="btnName_OnClick()" dark color="black">确定</v-btn>
          </div>

          <div v-if="showInputTodo">
            <v-text-field
              v-model="text_todo"
              label="萌猫问：你在干什么呢？"
              dark
            ></v-text-field>
            <div>
              <v-btn v-if="add_todo" @click="btnCancelTodo_OnClick()" dark color="grey">取消</v-btn> &nbsp;
              <v-btn :disabled="!text_todo" @click="btnTodo_OnClick()" dark color="black">确定</v-btn>
            </div>
          </div>

        </div>
        <div style="position: fixed;bottom:20%;left: 10%;right: 10%;;text-align: center;">
          <div v-if="showTodoList">

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <ul 
                  class="todo-list"
                  v-bind="attrs"
                  v-on="on"
                  >
                  <li><span style="font-size:16px">萌猫陪你一起：</span></li>
                  <li v-for="(item,index) in $store.getters.todo">{{ item.text }}</li>
                </ul>
              </template>
              <v-list dark>
                <v-list-item>
                  <v-list-item-title @click="btnAddTodo_OnClick()"><v-icon left>mdi-plus-circle</v-icon>添加一项</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="btnResetTodo_OnClick()"><v-icon left>mdi-trash-can</v-icon>清空列表</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style lang="less" scoped>
  .todo-list {
    font-size: 24px;
    color: #fff;
    background-color: rgba(0,0,0,.3);

    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }

  }
</style>

<script>
  export default {
    data: () => ({
      my_name: '',
      current_time: '',
      current_date: '',
      current_say: '',
      int_time: null,
      re_input_my_name: false,
      text_todo: '',
      menu_name: false,
      add_todo: false,
      home_cat_photo: '0001.jpg',
      cat_chat: false,
      chat_talk_content: ''

    }),
    components: {
    },
    created(){
      this.refresh_current_time()
    },
    mounted(){
      this.int_time = setInterval(()=>{
        this.refresh_current_time();
      },1000)
    },
    destroyed(){
      if (this.int_time){
        clearInterval(this.int_time)
      }
    },
    computed:{
      showTodoList(){
        if (this.cat_chat){
          return false;
        }
        if (!this.showInputMyName && !this.showInputTodo && this.$store.getters.todo){
          return true;
        }
        return false;
      },
      showInputCatChat(){
        return this.cat_chat;
      },
      showInputMyName(){
        if (this.cat_chat){
          return false;
        }
        if (this.$store.getters.getMyName){
          return false;
        }
        return true;
      },
      showInputTodo(){
        if (this.cat_chat){
          return false;
        }
        if (this.add_todo){
          return true;
        }
        if (this.$store.getters.getMyName){
          if (!this.$store.getters.todo || ('null' == this.$store.getters.todo)){
            return true;
          }
          return false;
        }
        return false;
      }
    },
    methods: {
      btnCancelCatTalk_OnClick(){
        this.cat_chat = false;
      },
      async btnCatTalk_OnClick(){
        await this.$store.dispatch('openAiSendMessage', {
          content: this.chat_talk_content
        })
        this.chat_talk_content = '';
        this.$refs.textChatTalk.focus()
      },
      btnChat_OnClick(){
        this.cat_chat = true;
      },
      btnRandomHomeCat_OnClick(){
        this.randomHomeCat();
      },
      randomHomeCat(){
        const MAX_N = 15
        let n = 1 + Math.floor(Math.random() * (MAX_N+1));
        if (n > MAX_N){
          n = MAX_N
        }
        let url = ('000' + n).substr(-4) + '.jpg';
        if (url == this.home_cat_photo){
          n = 1 + (n+1) % MAX_N
          url = ('000' + n).substr(-4) + '.jpg';
        }
        this.home_cat_photo = url;
      },
      btnCancelTodo_OnClick(){
        this.add_todo = false;
      },
      btnAddTodo_OnClick(){
        this.add_todo = true;
      },
      btnTodo_OnClick(){
        this.$store.dispatch('saveTodo', this.text_todo)
        this.text_todo = ''
        this.add_todo = false;
      },
      btnResetTodo_OnClick(){
        this.$store.dispatch('saveTodo', '')
      },
      btnResetName_OnClick(){
        this.$store.dispatch('saveMyName', '')
      },
      btnName_OnClick(){
        this.$store.dispatch('saveMyName', this.my_name)
        this.my_name = ''
      },
      refresh_current_time(){
        let d = new Date();
        let dHours = ('0' + d.getHours()).substr(-2);
        let dMinutes = ('0' + d.getMinutes()).substr(-2);
        this.current_time = `${dHours}:${dMinutes}`
        this.current_date = `${d.getMonth()+1}月${d.getDate()}日`
        let dh =d.getHours();
        if ((dh => 6) && (dh < 9)){
          this.current_say = '早晨'
        }else if ((dh => 9) && (dh < 12)){
          this.current_say = '上午好'
        }else if ((dh => 12) && (dh < 13)){
          this.current_say = '中午好'
        }else if ((dh => 13) && (dh < 17)){
          this.current_say = '下午好'
        }else if ((dh => 17) && (dh < 18)){
          this.current_say = '傍晚好'
        }else if ((dh => 18) && (dh < 24)){
          this.current_say = '晚上好'
        }else if ((dh => 0) && (dh < 3)){
          this.current_say = '午夜好'
        }else if ((dh => 3) && (dh < 6)){
          this.current_say = '凌晨好'
        }
        if (this.$store.getters.getMyName){
          this.current_say = `萌猫说 : ${this.$store.getters.getMyName}, ${ this.current_say } 喵~`
        }else{
          this.current_say = `萌猫说 : ${ this.current_say } 喵~`
        }
      },
      btnRiLi_OnClick(){
        this.$router.push('/rili')
      },
      btnWelcome_OnClick(){
        this.$router.push('/welcome')
      }
    }
  }
</script>
