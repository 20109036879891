<template>
    <v-app-bar
      app
      color="white"
      dark
    >
      <div @click="btnHome_OnClick()" class="d-flex align-center" style="color:#000; font-size: 26px">
        
        <v-img
          alt="萌猫日历"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="40"
        />
        萌猫日历

        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="btnRiLi_OnClick()"
        text
      >
        <!-- <span class="mr-2">Latest Release</span> -->
        <v-icon color="#999" middle>mdi-calendar-range</v-icon>
      </v-btn>
    </v-app-bar>

</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    btnHome_OnClick() {
      this.$router.push('/')
    },
    btnRiLi_OnClick(){
      this.$router.push('/rili')
    }
  }
};
</script>
