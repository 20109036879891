import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"app":"","color":"white","dark":""}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"color":"#000","font-size":"26px"},on:{"click":function($event){return _vm.btnHome_OnClick()}}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"萌猫日历","contain":"","src":require('@/assets/logo.png'),"transition":"scale-transition","width":"40"}}),_vm._v(" 萌猫日历 ")],1),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.btnRiLi_OnClick()}}},[_c(VIcon,{attrs:{"color":"#999","middle":""}},[_vm._v("mdi-calendar-range")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }