import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import md5 from 'md5'

const RR_CRYPTO_KEY = '&mgJkUBCAT!TBtaAeC';
function rrck(){
  let d = new Date().getTime();
  d = d / 100000;
  d = Math.round(d);
  return d;
}


const rrEncrypto = (message) => {
  const CryptoJS = require("crypto-js");
  let rrck1 = rrck();
  let rck = Number(rrck1) + (-Math.floor(Math.random() * 10));
  let ciphertext = CryptoJS.AES.encrypt(message, `${RR_CRYPTO_KEY}_${rck}`).toString();
  return ciphertext;
}
const rrDecrypto = (ciphertext) => {
  const CryptoJS = require("crypto-js");
  let rck = rrck();
  let bytes;
  let originalText;
  for (let i = 0; i >= -30; i--){
    try {
      bytes  = CryptoJS.AES.decrypt(ciphertext, `${RR_CRYPTO_KEY}_${(Number(rck)+i)}`);
      originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (originalText){
        let o = JSON.parse(originalText);
        if (o){
          if (Object.prototype.toString.call(o) =='[object Object]'){
            return originalText; 
          }
          if (Array.isArray(o)){
            return originalText;
          }
        }
      }
    }catch(e){
      //nothing
    }
  }
  return '{"url":"hello"}'
}

// const DOMAIN = 'http://192.168.10.105:8282';
// const DOMAIN = 'http://192.168.206.89:9191';
// const DOMAIN = 'https://cat.ubears.net';
const DOMAIN = ''

const lunar = require('chinese-lunar');
const lunar_month = {
  1: '正月',
  2: '二月',
  3: '三月',
  4: '四月',
  5: '五月',
  6: '六月',
  7: '七月',
  8: '八月',
  9: '九月',
  10: '十月',
  11: '冬月',
  12: '腊月',
};
const lunar_day = {
  1: '初一',
  2: '初二',
  3: '初三',
  4: '初四',
  5: '初五',
  6: '初六',
  7: '初七',
  8: '初八',
  9: '初九',
  10: '初十',
  11: '十一',
  12: '十二',
  13: '十三',
  14: '十四',
  15: '十五',
  16: '十六',
  17: '十七',
  18: '十八',
  19: '十九',
  20: '二十',
  21: '廿一',
  22: '廿二',
  23: '廿三',
  24: '廿四',
  25: '廿五',
  26: '廿六',
  27: '廿七',
  28: '廿八',
  29: '廿九',
  30: '三十',
  31: '卅一',
  32: '卅二',
};

const jie_qi_24 = {
  "01-06": "小寒",
  "01-20": "大寒",
  "02-04": "立春",
  "02-19": "雨水",
  "03-05": "惊蛰",
  "03-20": "春分",
  "04-05": "清明",
  "04-20": "谷雨",
  "05-05": "立夏",
  "05-21": "小满",
  "06-05": "芒种",
  "06-21": "夏至",
  "07-07": "小暑",
  "07-22": "大暑",
  "08-07": "立秋",
  "08-23": "处暑",
  "09-07": "白露",
  "09-23": "秋分",
  "10-08": "寒露",
  "10-23": "霜降",
  "11-07": "立冬",
  "11-22": "小雪",
  "12-07": "大雪",
  "12-22": "冬至"
}

const gong_li_jie_ri = {
  "01-01": "元旦",
  "02-14": "情人节",
  "03-08": "妇女节",
  "03-12": "植树节",
  "03-15": "消费者权益日",
  "03-17": "国医节",
  "04-01": "愚人节",
  "04-07": "世界卫生日",
  "04-22": "世界地球日",
  "05-01": "劳动节",
  "05-04": "青年节",
  "06-01": "儿童节",
  "08-01": "建军节",
  "09-10": "教师节",
  "10-01": "国庆节",
  "12-24": "平安夜",
  "12-25": "圣诞节",
}

const nong_li_jie_ri = {
  "正月初一": "春节",
  "正月初七": "人日",
  "正月十五": "元宵节",
  "二月初二": "龙抬头",
  "五月初五": "端午节",
  "七月初七": "七夕节",
  "七月十五": "中元节",
  "八月十五": "中秋节",
  "九月初九": "重阳节",
  "腊月初八": "腊八节",
  "腊月廿三": "小年",
  "腊月三十": "除夕",
}
Vue.use(Vuex)

export default new Vuex.Store({
  state: {  
    current_date_info: {},
    current_qian_info: {},
    rnd: null,
    current_chat_answer: '',
    current_chat_loading: false
  },
  getters: {
    currentChatLoading: (state, getters) => {
      state.rnd;
      return state.current_chat_loading
    },
    currentChatAnswer: (state, getters) => {
      state.rnd;
      return state.current_chat_answer
    },
    chatUserId: (state, getters) => {
      state.rnd;
      try {
        if (localStorage.chat_user_id){
          return localStorage.chat_user_id
        }
        localStorage.chat_user_id = md5(Math.random() + Math.random())
        return localStorage.chat_user_id
      }catch(e){
        //nothing
      }
      return 'anyone'
    },
    todo: (state, getters) => {
      state.rnd;
      try {
        return JSON.parse(localStorage.todo)
      }catch(e){
        //nothing
        console.log('todo err', e, localStorage.todo)
      }
      return ''
    },
    getMyName: (state, getters) => {
      state.rnd;
      if (localStorage.my_name){
        return localStorage.my_name
      }
      return ''
    },
    isToday: (state, getters) => (datestr) => {
      const date_cur = new Date(); 
      const date = new Date(datestr); 
      if (date_cur.getFullYear() == date.getFullYear()){
        if (date_cur.getMonth() == date.getMonth()){
          if (date_cur.getDate() == date.getDate()){
            return true
          }
        }
      }
      return false;
    },
    gongliDay: (state, getters) => (datestr) => {

      let d = new Date(datestr);
      return d.getDate()
    },
    getMDName: (state, getters) => (month, day) => {

      let m = month
      if (parseInt(m) < 10){
        m = `0${month}`
      }
      let d = day
      if (parseInt(d) < 10){
        d = `0${day}`
      }
      return `${m}-${d}`

    },
    lunarDateGongLiClassName: (state, getters) => (datestr) => {
      const date = new Date(datestr);
      let md_name = getters.getMDName(date.getMonth()+1, date.getDate())
      if (jie_qi_24[md_name]){
        return 'rili-text-24-jie-qi'
      }
      if (gong_li_jie_ri[md_name]){
        return 'rili-text-gong-li-jie-ri'
      }
      const lunarDate = lunar.solarToLunar(date);
      if (!lunarDate.leap){
        let nong_li_title = `${lunar_month[lunarDate.month]}${lunar_day[lunarDate.day-1]}`
        if (nong_li_jie_ri[nong_li_title]){
          return 'rili-text-nong-li-jie-ri'
        }
      }
      return ''
    },
    lunarDateGongLi: (state, getters) => (datestr) => {

      const date = new Date(datestr);
      const lunarDate = lunar.solarToLunar(date);

      //先判断当天是否显示节日名称
      let md_name = getters.getMDName(date.getMonth()+1, date.getDate())
      if (jie_qi_24[md_name]){
        return jie_qi_24[md_name]
      }

      if (gong_li_jie_ri[md_name]){
        return gong_li_jie_ri[md_name]
      }

      //月首，是否闰月？
      if ((lunarDate.day-1) <= 1){
        let d1 = lunar_month[lunarDate.month]
        if (lunarDate.leap){
          d1 = '闰' + d1
        }
        return d1
        // return lunar_month[lunarDate.leapMonth]
      }else{

          
        //先判断当天是否农历节日，若是，则不再显示常规日期
        if (!lunarDate.leap){
          let nong_li_title = `${lunar_month[lunarDate.month]}${lunar_day[lunarDate.day-1]}`
          if (nong_li_jie_ri[nong_li_title]){
            return ''
          }
        }

        return lunar_day[lunarDate.day-1];
      }

    },
    lunarDateNongLi: (state, getters) => (datestr) => {

      const date = new Date(datestr);
      const lunarDate = lunar.solarToLunar(date);


      if (!lunarDate.leap){
        let nong_li_title = `${lunar_month[lunarDate.month]}${lunar_day[lunarDate.day-1]}`
        if (nong_li_jie_ri[nong_li_title]){
          return nong_li_jie_ri[nong_li_title]
        }
      }

      return ''


    },
    lunarDate: (state, getters) => (datestr) => {

      const date = new Date(datestr);
      const lunarDate = lunar.solarToLunar(date);

      //先判断当天是否显示节日名称
      let md_name = getters.getMDName(date.getMonth()+1, date.getDate())
      if (jie_qi_24[md_name]){
        return jie_qi_24[md_name]
      }

      if (gong_li_jie_ri[md_name]){
        return gong_li_jie_ri[md_name]
      }

      if (!lunarDate.leap){
        let nong_li_title = `${lunar_month[lunarDate.month]}${lunar_day[lunarDate.day-1]}`
        if (nong_li_jie_ri[nong_li_title]){
          return nong_li_jie_ri[nong_li_title]
        }
      }

      //月首，是否闰月？
      if ((lunarDate.day-1) <= 1){
        let d1 = lunar_month[lunarDate.month]
        if (lunarDate.leap){
          d1 = '闰' + d1
        }
        return d1
        // return lunar_month[lunarDate.leapMonth]
      }else{

        
        return lunar_day[lunarDate.day-1];
      }

    },
    currentDateName: (state, getters) => {
      let D = new Date();
      let datestr = `${D.getFullYear()}-${D.getMonth()+1}-${D.getDate()}`
      return datestr;
    },
    currentDateInfo: (state, getters) => {
      return state.current_date_info;
    },
    CDI: (state, getters) => {
      return getters.currentDateInfo;
    },
    currentQianInfo: (state, getters) => {
      return state.current_qian_info;
    },
    CQI: (state, getters) => {
      return getters.currentQianInfo;
    },
  },
  mutations: {
  },
  actions: {
    rnd ({ commit, state, dispatch }){
      state.rnd = Math.random();
    },
    saveTodo ({ commit, state, dispatch }, text) {
      if (!text){
        localStorage.todo = ''
        dispatch('rnd')
        return;
      }
      try {
        let todo = JSON.parse(localStorage.todo)
        todo.push({text:text, done:false})
        localStorage.todo = JSON.stringify(todo);
      }catch(e){
        localStorage.todo = JSON.stringify([{text:text, done:false}]);
      }
      dispatch('rnd')
    },
    saveMyName ({ commit, state, dispatch }, name) {
      // return new Promise((reslove, reject) => {
        localStorage.my_name = name
        dispatch('rnd')
      // })
    },
    async sleep ({ commit, state }, s) {
      return new Promise((reslove, reject) => {
        setTimeout(()=>{
          reslove()
        }, s)
      })
    },
    async openAiSendMessage ({ commit, state, getters }, { content }) {
      // console.log('getDate', datename)
      try {

        state.current_chat_loading = true;
        let d = await axios({
          method: 'post',
          url: `${DOMAIN}/wx/send_message`,
          "content-type": "application/json",
          data: {
            do: rrEncrypto(JSON.stringify({
              content: content,
              userid: getters.chatUserId,
              type: 'gay'
            }))
          }
        })
        state.current_chat_answer = d.data.content
        // state.current_chat_answer = JSON.parse(JSON.stringify(state.current_chat_answer))
        // console.log(state.current_date_info)
        state.current_chat_loading = false;
        return state.current_chat_answer

      }catch(e){

      }

      state.current_chat_loading = false;
      state.current_chat_answer = '不好意思，萌萌刚刚在发呆，没听清，可以再重复一遍吗？'
      return state.current_chat_answer
    },
    async getDate ({ commit, state }, { datename }) {
      // console.log('getDate', datename)
      let d = await axios({
        method: 'post',
        url: `${DOMAIN}/wx/getdate`,
        "content-type": "application/json",
        data: {
          datename: datename
        }
      })
      state.current_date_info = d.data.data
      state.current_date_info = JSON.parse(JSON.stringify(state.current_date_info))
      // console.log(state.current_date_info)
      return d.data.data
    },
    async getQian ({ commit, state }, params) {

      if ((params && params.force) || !localStorage.qian_hash){
        localStorage.qian_hash = Math.random()
      }
      let d = await axios({
        method: 'post',
        url: `${DOMAIN}/wx/getqian`,
        "content-type": "application/json",
        data: {
          hash: localStorage.qian_hash
        }
      })
      state.current_qian_info = d.data.data
      state.current_qian_info = JSON.parse(JSON.stringify(state.current_qian_info))
      return d.data.data
    },
  },
  modules: {
  }
})
