<template>
  <div class="CatPlaying" :style="wrapStyle">
    <div 
      :class="homeCatClass" 
      :style="homeCatStyle"
     />
  </div>
</template>

<style lang="less" scoped>
  .CatPlaying {
    position: fixed;
    left: 0;
    top: 100px;
    right: 0;
    bottom: 0;
    z-index: 5
  }
  .dancing_cat {
    margin: 0 auto;
    background-position: 0 0;
    width: 500px;
    height: 600px;
  }
</style>

<script>
  export default {
    props: {
      catNum: {
        default: -1
      }
    },
    data: () => ({
      homeCatStyle: '',
      homeCatClass: '',
      background_src: '',
      dancing_num: 0,
      showLoading: false,
      catLoaded: false,
      wrapStyle: ''
    }),
    mounted(){
      this.initCat()
    },
    computed:{
      catDancingNumStr(){
        const MAX_CAT_NUM = 4;
        let numstr = ''
        let nn = this.catNum
        if (-1 == nn){
          nn = 1 + Math.ceil(Math.random()*MAX_CAT_NUM);
          if (nn > MAX_CAT_NUM){
            nn = MAX_CAT_NUM;
          }
        }
        if (parseInt(nn) < 10){
          numstr = `0${nn}`
        }else{
          numstr = `${nn}`
        }
        // return `@/assets/images/dancing_cat/dancing_cat_${numstr}.png`
        return numstr
      }
    },
    methods: {
      async catDancing(){
        this.dancing_num++;
        if (this.dancing_num >= 6){
          this.dancing_num = 0;
        }
        if (0 == this.dancing_num){
          this.homeCatClass = `dancing_cat animate__animated  animate__fadeInUpBig`
          this.homeCatStyle = `background-image:url(${this.background_src});background-position:${-1 * this.dancing_num * 500}px 0`
          await this.$store.dispatch('sleep', 1000)
        }else{
          this.homeCatClass = `dancing_cat animate__animated animate__faster animate__fadeIn`
          this.homeCatStyle = `background-image:url(${this.background_src});background-position:${-1 * this.dancing_num * 500}px 0`
          await this.$store.dispatch('sleep', 300)
        }

        if (this.dancing_num < 5){
          this.homeCatClass = `dancing_cat animate__animated  animate__fadeOut`
          await this.$store.dispatch('sleep', 400)
          this.catDancing()
        }else{
          this.homeCatClass = `dancing_cat animate__animated  animate__fadeOutLeft`
          await this.$store.dispatch('sleep', 800)
          this.wrapStyle = ''
          this.$router.push(this.gotoPath)
        }



      },
      initCat(){

        var background = new Image();
        background.src = require(`@/assets/images/dancing_cat/dancing_cat_${this.catDancingNumStr}.png`);
        background.onload = () => {
          this.catLoaded = true;
          this.background_src = background.src
        }        

      },
      play(options){
        this.gotoPath = '/huangli'
        if (options){
          this.gotoPath = options.path
        }
        
        if (this.catLoaded){


          this.homeCatClass = `dancing_cat animate__animated animate__zoomIn animate__fadeInUpBig`
          this.homeCatStyle = `background-image:url(${this.background_src});background-position:0 0`

          this.dancing_num = -1;

          this.wrapStyle = 'background-color:rgba(255,255,255,0.8); transform: scale(.5); transform-origin: 125px 150px;'

          this.catDancing()

    
    

        }

      },
      btnHuangLi_OnClick(){
      },
      btnYunCheng_OnClick(){
      }
    }
  }
</script>
