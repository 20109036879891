<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import AppBar from '@/components/AppBar'

export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
    AppBar
  },
  methods: {
    btnHome_OnClick() {
      this.$router.push('/')
    },
    btnRiLi_OnClick(){
      this.$router.push('/rili')
    }
  }
};
</script>
